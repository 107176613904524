<page-header pageTitle="Land Use Blocks" [customRichTextTypeID]="richTextID" [templateRight]="templateRight"></page-header>

<ng-template #templateRight>
    <a class="float-right btn btn-primary mr-2" [href]="url + '/LandUseBlockGeometry/UpdateLandUseBlockGeometry'"> <icon [icon]="'Upload'"></icon> Upload Land Use Blocks </a>
    <a class="float-right btn btn-primary" [href]="url + '/LandUseBlockGeometry/DownloadLandUseBlockGeometry'"> <icon [icon]="'Download'"></icon> Download Land Use Blocks </a>
</ng-template>

<app-alert-display></app-alert-display>

<div class="page-body" [loadingSpinner]="{ isLoading, loadingHeight: 100 }">
    <neptune-grid
        *ngIf="landUseBlocks$ | async as landUseBlocks"
        height="800px"
        [rowData]="landUseBlocks"
        [columnDefs]="landUseBlockColumnDefs"
        downloadFileName="land-use-blocks"
        [pagination]="true"
        [sizeColumnsToFitGrid]="true"></neptune-grid>
</div>
