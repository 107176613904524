<div class="grid-12 page-wrapper">
    <div class="g-col-8">
        <app-alert-display></app-alert-display>
        <h1 class="text-primary mt-4 mb-4">OCST Planning Module</h1>

        <ng-container *ngIf="currentUser$ | async as currentUser">
            <div *ngIf="userIsUnassigned(currentUser)" class="alert alert-info">
                <div class="alert-content">
                    <i class="fa fa-info"></i>
                    <span *ngIf="userIsOCTAGrantReviewer(currentUser); else unassignedUserMessage">
                        Welcome {{ currentUser.FirstName }} {{ currentUser.LastName }}. You have successfully logged in to the OCST Planning Module, and an administrator has granted you
                        read-only access to Review Grant Programs. You may <a href="{{ requestSupportUrl() }}">Request Support</a> to contact the Administrators with any questions or
                        comments.
                    </span>
                    <ng-template #unassignedUserMessage>
                        <span>
                            Welcome {{ currentUser.FirstName }} {{ currentUser.LastName }}. You have successfully logged in to the OCST Planning Module, but your account is not yet
                            configured. You will receive an email from the Administrators when your account is ready to use. You may
                            <a href="{{ requestSupportUrl() }}">Request Support</a> to contact the Administrators with any questions or comments.
                        </span>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        
        <custom-rich-text [customRichTextTypeID]="richTextTypeID"></custom-rich-text>
    </div>

    <div class="g-col-4">
        <div *ngIf="currentUser$ | async as currentUser; else notSignedIn" class="platform-links">
            <div class="card platform-links-card" routerLink="projects">
                <div class="card-header">Project List</div>
                <div class="card-body">
                    <img class="card-img" src="/assets/main/project-list.png" />
                </div>
            </div>

            <div class="card platform-links-card" routerLink="planning-map">
                <div class="card-header">Planning Map</div>
                <div class="card-body">
                    <img class="card-img" src="/assets/main/planning-map.png" />
                </div>
            </div>
        </div>

        <ng-template #notSignedIn>
            <div class="card sign-in-card">
                <div class="card-header">Welcome</div>
                <div class="card-body">
                    <p class="mb-2">Log in to view your Account. Create an Account if you don't have one yet.</p>
                    <a (click)="login()" class="btn btn-primary">Login</a>
                    <a (click)="createAccount()" class="btn btn-primary">Create Account</a>
                </div>
                <div class="card-footer">
                    <p> Need help logging in?</p>
                    <a href="{{ forgotPasswordUrl() }}">Forgot Password</a> | <a href="{{ forgotUsernameUrl() }}">Forgot Username</a> |
                    <a href="{{ keystoneSupportUrl() }}">Request Support</a>
                </div>
            </div>
        </ng-template>
    </div>
</div>
