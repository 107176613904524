<page-header pageTitle="On-Land Visual Trash Assessments Area" [templateRight]="templateRight"></page-header>

<ng-template #templateRight>
    <a class="float-right btn btn-primary mr-2" [href]="url + '/OnlandVisualTrashAssessmentArea/BulkUploadOVTAAreas'"> <icon [icon]="'Upload'"></icon> Upload OVTA Areas </a>
    <a class="float-right btn btn-primary" [href]="url + '/OnlandVisualTrashAssessmentExport/ExportAssessmentGeospatialData'">
        <icon [icon]="'Download'"></icon> Download OVTA Areas
    </a>
</ng-template>

<app-alert-display></app-alert-display>

<div class="page-body" [loadingSpinner]="{ isLoading, loadingHeight: 100 }">
    <hybrid-map-grid
        [rowData]="onlandVisualTrashAssessmentAreas$ | async"
        [columnDefs]="ovtaAreaColumnDefs"
        downloadFileName="test"
        entityIDField="OnlandVisualTrashAssessmentAreaID"
        (onMapLoad)="handleMapReady($event)"
        [selectedValue]="ovtaAreaID"
        (selectedValueChange)="onSelectedOVTAAreaChanged($event)">
        <div mapLayers *ngIf="true">
            <selected-ovta-area-layer
                *ngIf="mapIsReady"
                [displayOnLoad]="true"
                [map]="map"
                [layerControl]="layerControl"
                [sortOrder]="10"
                [selectedOVTAAreaID]="ovtaAreaID"
                (layerBoundsCalculated)="handleLayerBoundsCalculated($event)"
                (ovtaAreaSelected)="onSelectedOVTAAreaChanged($event)"></selected-ovta-area-layer>
        </div>
        <div headerActions></div>
    </hybrid-map-grid>
</div>
