<page-header pageTitle="Trash Generating Unit"></page-header> <app-alert-display></app-alert-display>

<div class="page-body" [loadingSpinner]="{ isLoading, loadingHeight: 100 }">
    <neptune-grid
        *ngIf="trashGeneratingUnits$ | async as trashGeneratingUnits"
        height="800px"
        [rowData]="trashGeneratingUnits"
        [columnDefs]="trashGeneratingUnitsColumnDefs"
        downloadFileName="trash-generating-units"
        [pagination]="true"
        [sizeColumnsToFitGrid]="true"></neptune-grid>
</div>
