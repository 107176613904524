<page-header pageTitle="Modeled Performance and Grant Metrics" [templateRight]="templateRight"> </page-header>
<ng-template #templateRight>
    <a href="{{ getAboutModelingPerformanceURL() }}" target="_blank" class="float-right">About Modeling Performance</a>
</ng-template>
<workflow-body *ngIf="project$ | async as project">
    <app-alert-display></app-alert-display>
    <ng-container *ngIf="projectNetworkSolveHistories$ | async as projectNetworkSolveHistories">
        <div *ngIf="isMostRecentHistoryOfType(projectNetworkSolveHistories, ProjectNetworkHistoryStatusTypeEnum.Queued)" class="alert alert-info">
            <div class="alert-content">
                <i class="fa fa-info"></i>
                Results for this project are currently being calculated. Triggering a new calculation will be disabled until the current calculation completes.
            </div>
        </div>
        <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
        <hr />
        <div class="grid-12 p-3 m-1 mb-3">
            <div class="g-col-4">
                <button
                    class="btn btn-primary"
                    (click)="triggerModelRunForProject()"
                    [disabled]="isMostRecentHistoryOfType(projectNetworkSolveHistories, ProjectNetworkHistoryStatusTypeEnum.Queued) || delineations?.length == 0">
                    Calculate
                </button>
            </div>
            <div class="g-col-8">
                <p class="font-weight-bold">
                    Press 'Calculate' to run the model results for your project. You will be notified via email when the updated results are returned. Toggle the dropdown below to
                    view results for individual BMPs.
                </p>
                <p class="font-italic">{{ getModelResultsLastCalculatedText(projectNetworkSolveHistories) }}</p>
            </div>
        </div>
        <hr />
        <div class="grid-12">
            <div class="g-col-6">
                <table class="table d-block" style="overflow: auto" [style.max-height]="mapHeight">
                    <thead>
                        <th>BMP Name</th>
                        <th>
                            <field-definition fieldDefinitionType="TreatmentBMPType" [labelOverride]="'Type'"> </field-definition>
                        </th>
                        <th>Delineation Area (ac)</th>
                        <th>
                            <field-definition fieldDefinitionType="FullyParameterized"></field-definition>
                        </th>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let treatmentBMP of projectTreatmentBMPs"
                            class="selectable"
                            [class.selected]="selectedTreatmentBMP?.TreatmentBMPID == treatmentBMP.TreatmentBMPID"
                            (click)="selectFeatureImpl(treatmentBMP.TreatmentBMPID)">
                            <td>
                                {{ treatmentBMP.TreatmentBMPName }}
                            </td>
                            <td>
                                {{ treatmentBMP.TreatmentBMPTypeName }}
                            </td>
                            <td>
                                {{ getDelineationAcreageForTreatmentBMP(treatmentBMP.TreatmentBMPID) }}
                            </td>
                            <td>
                                {{ treatmentBMP.IsFullyParameterized ? "Yes" : "No" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="g-col-6">
                <neptune-map
                    *ngIf="boundingBox$ | async as boundingBox"
                    (onMapLoad)="handleMapReady($event)"
                    [mapHeight]="mapHeight"
                    [showLegend]="false"
                    [boundingBox]="boundingBox">
                    <regional-subbasins-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="10"></regional-subbasins-layer>
                    <stormwater-network-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="20"></stormwater-network-layer>
                    <jurisdictions-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="30"></jurisdictions-layer>
                    <wqmps-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="40"></wqmps-layer>
                    <delineations-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="50"></delineations-layer>
                    <inventoried-bmps-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="60"></inventoried-bmps-layer>
                </neptune-map>
            </div>
        </div>

        <div class="card mt-3 mb-3" *ngIf="project.HasModeledResults">
            <div class="card-header">Model Results</div>
            <div class="card-body">
                <model-results
                    [projectID]="projectID"
                    [treatmentBMPs]="projectTreatmentBMPs"
                    [delineations]="delineations"
                    [projectNetworkSolveHistories]="projectNetworkSolveHistories">
                </model-results>
            </div>
        </div>
        <div class="card mt-3 mb-3" *ngIf="project?.CalculateOCTAM2Tier2Scores">
            <div class="card-header">Grant Metrics</div>
            <div class="card-body">
                <grant-scores [project]="project"></grant-scores>
            </div>
        </div>
    </ng-container>
</workflow-body>

<div class="page-footer">
    <button class="btn btn-primary ml-auto" (click)="continueToNextStep()">Continue</button>
</div>
