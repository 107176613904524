<h1 class="mt-4 mb-4">OCST Trash Module</h1>
<app-alert-display></app-alert-display>

<div class="grid-12">
    <custom-rich-text class="g-col-8" [customRichTextTypeID]="richTextTypeID"></custom-rich-text>

    <div class="hero__actions card g-col-4">
        <ng-container *ngIf="currentUser$ | async as currentUser; else notSignedIn">
            <div class="card-header"><h3 class="card-title">Quick Actions</h3></div>
            <div class="card-body">
                <div class="hero__btns">
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" [href]="[ocstBaseUrl() + '/OnlandVisualTrashAssessment/Instructions/-1']">Begin OVTA</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" [routerLink]="['onland-visual-trash-assessment']">View All OVTAs</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" [routerLink]="['onland-visual-trash-assessment-area']">View All OVTA Areas</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a class="btn btn-primary" href="{{ requestSupportUrl() }}">Request Support</a>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-template #notSignedIn>
            <div class="card-header"><h3 class="card-title">Welcome.</h3></div>
            <div class="card-body">
                <p class="mb-2">Log in to view your Account. Create an Account if you don't have one yet.</p>
                <div class="hero__btns">
                    <div class="hero__btns-wrapper">
                        <a (click)="login()" class="btn btn-primary">Login</a>
                    </div>
                    <div class="hero__btns-wrapper">
                        <a (click)="createAccount()" class="btn btn-primary">Create Account</a>
                    </div>
                </div>
                <hr />
                <div>
                    <small style="font-size: 11px">
                        Need help logging in? <br />
                        <a href="{{ forgotPasswordUrl() }}">Forgot Password</a> | <a href="{{ forgotUsernameUrl() }}">Forgot Username</a> |
                        <a href="{{ keystoneSupportUrl() }}">Request Support</a>
                    </small>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<!-- <div>
    <btn-group-radio-input [options]="tabs" (change)="setActiveTab($event)" [default]="activeTab" [showIcons]="true"></btn-group-radio-input>
    <div [hidden]="activeTab !== 'Area-Based Results'">
        <neptune-map (onMapLoad)="handleMapReady($event)" mapHeight="1000px" [showLegend]="false">
            <ovta-area-layer *ngIf="mapIsReady" [displayOnLoad]="true" [map]="map" [layerControl]="layerControl" [sortOrder]="10"></ovta-area-layer>
        </neptune-map>
    </div>
    <div [hidden]="activeTab !== 'Load-Based Results'"></div>
    <div [hidden]="activeTab !== 'OVTA-Based Results'">
        <neptune-map (onMapLoad)="handleMapReady($event)" mapHeight="1000px" [showLegend]="false"> </neptune-map>
    </div>
</div> -->
