<page-header pageTitle="Proposed Treatment BMPs"></page-header>
<workflow-body [showLoadingSpinner]="isLoadingSubmit">
    <app-alert-display></app-alert-display>
    <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
    <div class="mt-3 mb-3 flex-between no-wrap">
        <i></i>
        <button class="btn btn-primary ml-auto" (click)="addTreatmentBMP()">Add New Treatment BMP</button>
    </div>
    <div class="grid-12">
        <div class="g-col-3" [style.height]="mapHeight" style="overflow-y: auto">
            <ul class="list-group">
                <li
                    class="list-group-item"
                    *ngFor="let treatmentBMP of projectTreatmentBMPs"
                    [class.selected]="selectedListItem == treatmentBMP.TreatmentBMPID"
                    (click)="selectTreatmentBMP(treatmentBMP.TreatmentBMPID)">
                    <strong>{{ treatmentBMP.TreatmentBMPName ?? "New Treatment BMP" }}</strong>
                    <p class="treatment-bmp-type">Type: {{ treatmentBMP.TreatmentBMPTypeID ? getTypeNameByTypeID(treatmentBMP.TreatmentBMPTypeID) : "Not Selected" }}</p>
                </li>
            </ul>
        </div>
        <div class="g-col-9" *ngIf="boundingBox$ | async as boundingBox">
            <neptune-map
                (onMapLoad)="handleMapReady($event)"
                [mapHeight]="mapHeight"
                [showLegend]="false"
                [style.cursor]="isEditingLocation ? 'crosshair' : ''"
                [boundingBox]="boundingBox">
                <regional-subbasins-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="10"></regional-subbasins-layer>
                <stormwater-network-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="20"></stormwater-network-layer>
                <jurisdictions-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="30"></jurisdictions-layer>
                <wqmps-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="40"></wqmps-layer>
                <delineations-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="50"></delineations-layer>
                <inventoried-bmps-layer *ngIf="mapIsReady" [displayOnLoad]="false" [map]="map" [layerControl]="layerControl" [sortOrder]="60"></inventoried-bmps-layer>
            </neptune-map>
        </div>
    </div>
    <div class="card mt-4" *ngIf="selectedTreatmentBMP; else noTreatmentBMPsCheckboxOption">
        <div class="card-body">
            <div class="grid-12">
                <div class="g-col-8">
                    <div class="field">
                        <div class="field-label required">Name</div>
                        <div class="units-wrapper">
                            <input type="text" name="name" [(ngModel)]="selectedTreatmentBMP.TreatmentBMPName" required />
                        </div>
                    </div>
                    <div class="field mt-3">
                        <label class="field-label required"><field-definition fieldDefinitionType="TreatmentBMPType"></field-definition></label>
                        <div *ngIf="selectedTreatmentBMP.TreatmentBMPID > 0; else treatmentBMPTypeSelect">
                            {{ getTypeNameByTypeID(selectedTreatmentBMP.TreatmentBMPTypeID) }}
                            <button class="btn btn-sm btn-secondary l-2" (click)="openEditTreatmentBMPTypeModal()">Change Type</button>
                        </div>
                        <ng-template #treatmentBMPTypeSelect>
                            <select name="type" [(ngModel)]="selectedTreatmentBMP.TreatmentBMPTypeID" (change)="updateModelingTypeOnTypeChange($event)" required>
                                <option *ngFor="let treatmentBMPType of treatmentBMPTypes" [value]="treatmentBMPType.TreatmentBMPTypeID">
                                    {{ treatmentBMPType.TreatmentBMPTypeName }}
                                </option>
                            </select>
                        </ng-template>
                    </div>
                    <div class="field mt-3">
                        <div class="field-label">Notes</div>
                        <div class="units-wrapper">
                            <textarea class="form-control" name="notes" style="height: 60px" placeholder="" [(ngModel)]="selectedTreatmentBMP.Notes"> </textarea>
                        </div>
                    </div>
                </div>
                <div class="g-col-4">
                    <div class="flex-between no-wrap">
                        <i></i>
                        <div>
                            <a class="btn btn-primary-outline mr-2" (click)="toggleIsEditingLocation()">
                                <i class="fa fa-edit mr-1"></i>{{ isEditingLocation ? "Save Location" : "Edit Location" }}
                            </a>
                            <a class="btn btn-danger-outline" (click)="onDelete()"> <i class="fa fa-trash mr-1"></i> Delete </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-12 mt-3">
                <div class="g-col-5">
                    <h3 class="mt-3">Location</h3>
                    <dl class="grid-12 mt-4" *ngIf="selectedTreatmentBMP.Latitude && selectedTreatmentBMP.Longitude; else noLocationSelected">
                        <dt class="g-col-5">Latitude</dt>
                        <dd class="g-col-7">
                            {{ selectedTreatmentBMP.Latitude }}
                        </dd>
                        <dt class="g-col-5">Longitude</dt>
                        <dd class="g-col-7">
                            {{ selectedTreatmentBMP.Longitude }}
                        </dd>
                        <dt class="g-col-5">
                            <field-definition fieldDefinitionType="Area" [labelOverride]="'Delineation Area'"></field-definition>
                        </dt>
                        <dd class="g-col-7" [class.font-italic]="!treatmentBMPHasDelineation(selectedTreatmentBMP.TreatmentBMPID)">
                            {{ getDelineationAreaForTreatmentBMP(selectedTreatmentBMP.TreatmentBMPID) }}
                        </dd>
                        <ng-container *ngIf="selectedTreatmentBMP.WatershedName">
                            <dt class="g-col-5">
                                <field-definition [fieldDefinitionType]="'Watershed'"></field-definition>
                            </dt>
                            <dd class="g-col-7">{{ selectedTreatmentBMP.WatershedName }}</dd>
                        </ng-container>
                    </dl>
                    <ng-template #noLocationSelected>
                        <div class="mt-3"><em>Location details will display once Treatment BMP location is selected</em></div>
                    </ng-template>
                </div>
                <div class="g-col-7">
                    <h3 class="mt-3">Modeling Attributes</h3>
                    <ng-container *ngIf="selectedTreatmentBMP.TreatmentBMPTypeID; else noTypeSelected">
                        <ng-container *ngIf="getModelingAttributeFieldsToDisplay(selectedTreatmentBMP.TreatmentBMPTypeID).length > 0; else modelingTypesNotApplicable">
                            <div class="mt-3">
                                <div
                                    class="field mb-2"
                                    *ngFor="let treatmentBMPModelingAttributeDefinitionDto of getModelingAttributeFieldsToDisplay(selectedTreatmentBMP.TreatmentBMPTypeID)">
                                    <ng-container *ngIf="isFieldWithDropdown(treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName); else numericModelingAttributeField">
                                        <label class="field-label">
                                            <field-definition [fieldDefinitionType]="treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName"></field-definition>
                                        </label>
                                        <select
                                            [(ngModel)]="selectedTreatmentBMP[treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName]"
                                            [name]="treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName">
                                            <option
                                                *ngFor="let dropdownItem of modelingAttributeDropdownItems[treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName]"
                                                [value]="dropdownItem.ItemID">
                                                {{ dropdownItem.ItemName }}
                                            </option>
                                        </select>
                                    </ng-container>
                                    <ng-template #numericModelingAttributeField>
                                        <div class="field-label">
                                            <field-definition [fieldDefinitionType]="treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName"></field-definition>
                                        </div>
                                        <div class="units-wrapper">
                                            <input
                                                type="number"
                                                class="form-control"
                                                [(ngModel)]="selectedTreatmentBMP[treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName]"
                                                [name]="treatmentBMPModelingAttributeDefinitionDto.ModelingAttributeName" />
                                            <span class="units">{{ treatmentBMPModelingAttributeDefinitionDto.Units }}</span>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-template #noTypeSelected>
                        <div class="mt-3"><em>Modeling Attributes will display once a Treatment BMP Type is selected.</em></div>
                    </ng-template>
                    <ng-template #modelingTypesNotApplicable>
                        <div class="mt-3">
                            <em> No pollutant load or volume reduction will be calculated for this type of Treatment BMP therefore modeling attributes are not applicable. </em>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noTreatmentBMPsCheckboxOption>
        <div class="mt-4 mb-4 flex-between no-wrap">
            <i></i>
            <div class="ml-auto">
                <input type="checkbox" name="no-treatment-bmps" [(ngModel)]="project.DoesNotIncludeTreatmentBMPs" />
                <label for="no-treatment-bmps" class="ml-2">My project does not include any Stormwater Treatment BMPs</label>
            </div>
        </div>
    </ng-template>
    <ng-template #noTreatmentBMPs>
        <p class="text-italic">No BMPs have been added to this project yet</p>
    </ng-template>

    <ng-template #editTreatmentBMPTypeModal let-modal>
        <div class="modal-header">
            <h2 class="section-title">Change Treatment BMP Type</h2>
            <button type="button" class="close" (click)="closeEditTreatmentBMPTypeModal()"><i class="fa fa-times-circle"></i></button>
        </div>
        <div class="modal-body">
            <p>
                This will change BMP "{{ selectedTreatmentBMP.TreatmentBMPName }}" to the selected BMP Type. Custom Attributes and Model Attributes of this BMP which are not
                applicable to the new BMP type will be discarded.
            </p>
            <p>Are you sure you wish to proceed?</p>
            <div class="mt-4 field">
                <label class="field-label">
                    <field-definition fieldDefinitionType="TreatmentBMPType"></field-definition>
                </label>
                <select name="type" class="form-control mb-3" [(ngModel)]="selectedTreatmentBMPType" required>
                    <option *ngFor="let treatmentBMPType of treatmentBMPTypes" [value]="treatmentBMPType.TreatmentBMPTypeID">
                        {{ treatmentBMPType.TreatmentBMPTypeName }}
                    </option>
                </select>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="changeTreatmentBMPType(selectedTreatmentBMPType)">Update</button>
                <button type="button" class="btn btn-secondary" (click)="closeEditTreatmentBMPTypeModal()">Cancel</button>
            </div>
        </div>
    </ng-template>
</workflow-body>

<div class="page-footer">
    <button class="btn btn-primary mr-2" (click)="save()" [disabled]="isLoadingSubmit">Save</button>
    <button class="btn btn-primary-outline ml-auto" (click)="save(true)" [disabled]="isLoadingSubmit">Save & Continue</button>
</div>
