<ng-container *ngIf="currentProject$ | async as project">
    <page-header [pageTitle]="project.ProjectName" [templateRight]="templateRight"> </page-header>
    <ng-template #templateRight>
        <a [routerLink]="getWorkflowLink(project)" class="btn btn-primary mr-2">Edit</a>
        <a (click)="makeProjectCopy(project)" class="btn btn-primary-outline" [class.disabled]="isCopyingProject">
            <span *ngIf="isCopyingProject" class="fa fa-spinner loading-spinner"></span>
            Copy Project
        </a>
    </ng-template>
    <app-alert-display></app-alert-display>

    <div class="grid-12 mt-2 mb-3">
        <div class="card">
            <div class="card-header">
                <span class="card-title">Project Basics</span>
            </div>
            <div class="card-body">
                <dl class="grid-12">
                    <dt class="g-col-4">Name</dt>
                    <dd class="g-col-8">
                        {{ project?.ProjectName }}
                    </dd>

                    <dt class="g-col-4">Description</dt>
                    <dd class="g-col-8">
                        {{ project?.ProjectDescription }}
                        <em *ngIf="!project?.ProjectDescription" class="text-muted">None Provided</em>
                    </dd>

                    <dt class="g-col-4">Status</dt>
                    <dd class="g-col-8">
                        {{ project?.ProjectStatus.ProjectStatusDisplayName }}
                    </dd>

                    <dt class="g-col-4">Date Created</dt>
                    <dd class="g-col-8">
                        {{ project?.DateCreated | date : "MM/dd/yyyy" }}
                    </dd>

                    <dt class="g-col-4">Date Updated</dt>
                    <dd class="g-col-8">
                        {{ project?.DateUpdated | date : "MM/dd/yyyy" }}
                    </dd>

                    <dt class="g-col-4">Project Owner</dt>
                    <dd class="g-col-8">
                        {{ project?.Organization.OrganizationName }}
                    </dd>

                    <dt class="g-col-4"><field-definition fieldDefinitionType="Jurisdiction"></field-definition></dt>
                    <dd class="g-col-8">
                        {{ project?.StormwaterJurisdiction.Organization.OrganizationName }}
                    </dd>

                    <dt class="g-col-4">Primary Contact</dt>
                    <dd class="g-col-8">
                        {{ project?.PrimaryContactPerson.FullName }}
                    </dd>

                    <dt class="g-col-4">Additional Contact Information</dt>
                    <dd class="g-col-8">
                        {{ project?.AdditionalContactInformation }}
                        <em *ngIf="!project?.AdditionalContactInformation" class="text-muted">None Provided</em>
                    </dd>

                    <dt class="g-col-4">Does Project Include Stormwater Treatment BMPs?</dt>
                    <dd class="g-col-8">
                        {{ project?.DoesNotIncludeTreatmentBMPs ? "No" : "Yes" }}
                    </dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="mt-3 mb-3" *ngIf="!project?.DoesNotIncludeTreatmentBMPs">
        <div class="card">
            <div class="card-header"><span class="card-title">Treatment BMPs</span></div>
            <div class="card-body">
                <project-map #treatmentBMPMapAndModelingAttributes [projectID]="project.ProjectID"> </project-map>
            </div>
        </div>
    </div>
    <div class="mt-3 mb-3" *ngIf="!project?.DoesNotIncludeTreatmentBMPs && project?.HasModeledResults">
        <div class="card" *ngIf="modeledResultsData$ | async as modeledResultsData">
            <div class="card-header"><span class="card-title">Model Results</span></div>
            <div class="card-body">
                <model-results
                    [projectID]="project.ProjectID"
                    [treatmentBMPs]="modeledResultsData.treatmentBMPs"
                    [delineations]="modeledResultsData.delineations"
                    [projectNetworkSolveHistories]="modeledResultsData.projectNetworkSolveHistories">
                </model-results>
            </div>
        </div>
    </div>
    <div class="row mt-3 mb-3" *ngIf="attachments$ | async as attachments">
        <div class="col-12" *ngIf="attachments.length > 0">
            <div class="card">
                <div class="card-header"><span class="card-title">Attachments</span></div>
                <div class="card-body">
                    <attachments-display [attachments]="attachments"></attachments-display>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 mb-3" *ngIf="project.CalculateOCTAM2Tier2Scores">
        <div class="col-12">
            <div class="card">
                <div class="card-header"><span class="card-title">Grant Metrics</span></div>
                <div class="card-body">
                    <grant-scores [project]="project"></grant-scores>
                </div>
            </div>
        </div>
    </div>
</ng-container>
