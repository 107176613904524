<page-header pageTitle="On-Land Visual Trash Assessments" [customRichTextTypeID]="customRichTextID" [templateRight]="templateRight"></page-header>

<ng-template #templateRight>
    <a class="float-right btn btn-primary mr-2" [href]="url + '/OnlandVisualTrashAssessment/BulkUploadOTVAs'"> <icon [icon]="'Upload'"></icon> Upload OVTAs </a>
</ng-template>

<app-alert-display></app-alert-display>

<div class="page-body" [loadingSpinner]="{ isLoading, loadingHeight: 100 }">
    <neptune-grid
        *ngIf="onlandVisualTrashAssessments$ | async as onlandVisualTrashAssessments"
        height="800px"
        [rowData]="onlandVisualTrashAssessments"
        [columnDefs]="ovtaColumnDefs"
        downloadFileName="onland-visual-trash-assessments"
        [pagination]="true"
        [sizeColumnsToFitGrid]="true"></neptune-grid>
</div>
